import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Badge } from 'reactstrap';
import _ from 'lodash';
import "@fontsource/knewave"

function ordianl(num) {
  num = num + '';
  if (num === '1') return <>1<sup>st</sup></>;
  if (num === '2') return <>2<sup>nd</sup></>;
  if (num === '3') return <>3<sup>rd</sup></>;
  return <>{num}<sup>th</sup></>;
}

function color(num) {
  if (num === '1') return "goldenrod";
  if (num === '2') return "gray";
  if (num === '3') return "coral";
  if (num === '4') return "turquoise";
  if (num === '5') return "orchid";
  if (num === '6') return "#6A5ACD";
  if (num === '7') return "#9ACD32";
}

const Leaderboard = ({ players, placements, ...props }) => {
  players.forEach(player => {
    player.Points = _.sum(placements.filter(placement => placement.Player === player.partitionKey + player.rowKey).map(x => x.Points))
  });
  let groupedPlayers = _.groupBy(players, player => player.Points);
  let nextPlace = 0;
  Object.keys(groupedPlayers).sort((a, b) => b - a).forEach(points => {
    nextPlace += groupedPlayers[points].length;
    groupedPlayers[points].forEach(player => player.Place = nextPlace);
  })
  groupedPlayers = _.groupBy(players, player => player.Place);

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h4">Leaderboard</CardTitle>
        <CardText>
          <div className="row">
            <div className="col-6">
              {Object.keys(groupedPlayers).sort((a, b) => a - b).map(place => 
                <div key={place} className="row">
                  {groupedPlayers[place].map(player =>
                    <div key={player.Id} className="row">
                      <div className="col-3">
                        <span style={{ fontFamily: "Knewave", color: color(place), textShadow: "-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black" }}>{ordianl(place)}</span>
                        {groupedPlayers[place].length > 1 && place < 100 ? "🔗" : ""}
                      </div>
                      <div className="col-6">{player.Name}</div>
                      <div className="col-3">{player.Points !== undefined ? "💎" + player.Points : ""}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </CardText>
      </CardBody>
    </Card>
  );
}

export default Leaderboard;
