import logo from './logo.svg';
import './App.css';
import Match from './components/Match/Match';
import Event from './components/Event/Event';
import 'bootstrap/dist/css/bootstrap.css';
import { Container } from 'reactstrap';
import { TableClient, AzureSASCredential } from "@azure/data-tables";
import { useEffect, useState } from 'react';
import Leaderboard from './components/Leaderboard/Leaderboard';

async function readTable(tableName) {
  let client = new TableClient(
    "https://league267532.table.core.windows.net",
    tableName,
    new AzureSASCredential("?sv=2021-10-04&ss=btqf&srt=sco&st=2023-01-19T22%3A27%3A32Z&se=2023-12-31T22%3A27%3A00Z&sp=rl&sig=cBDAQTZmZDWX%2BxBAhv5%2FaPCFP8YFgrmxVABkxTwJcac%3D")
  );
  let entities = [];
  for await (const entity of client.listEntities()) {
    entities.push(entity);
  }
  return entities;
}

function App() {
  const [players, setPlayers] = useState([]);
  const [matches, setMatches] = useState([]);
  const [events, setEvents] = useState([]);
  const [placements, setPlacements] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      setPlayers(await readTable("Players"));
      setMatches(await readTable("Matches"));
      setEvents(await readTable("Events"));
      setPlacements(await readTable("Placement"));
      setReady(true);
    })();
  }, [])

  return (
    <>
      {ready ? (
        <div className="p-4">
          <div className="row mb-3">
            <div className="col-12">
              <Leaderboard players={players} placements={placements} />
            </div>
            {events.filter(event => event.Show).sort((a, b) => a.Priority - b.Priority).map(event => (
              <div className="col-12 mt-3">
                <Event event={event} matches={matches.filter(match => match.Event === event.partitionKey + event.rowKey)} players={players} placements={placements} />
              </div>
            ))}
          </div>
        </div>
      ) : <></>}
    </>
  );
}

export default App;
