import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import Match from '../Match/Match';


const Event = ({event, matches, players, placements, ...props}) => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <Card>
            <CardBody>
              <CardTitle tag="h4">{event.Name}</CardTitle>
              <CardText>
              <div className="row">
                {matches.map((match, i) => <div className="col" style={i ? { borderLeft: "1px solid var(--bs-border-color-translucent)" } : {}}><Match match={match} players={players} placements={placements} /></div>)}
              </div>
              </CardText>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Event;
