import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, Badge } from 'reactstrap';
import _ from 'lodash';
import "@fontsource/knewave"

function byPlayerNumber(a, b) {
  if (a.rowKey < b.rowKey) { 
      return -1;
  } else if (a.rowKey > b.rowKey) {
      return 1
  }
  return 0;
}

function ordianl(num) {
  num = num + '';
  if (num === '100') return <>N/A</>;
  if (num === '1') return <>1<sup>st</sup></>;
  if (num === '2') return <>2<sup>nd</sup></>;
  if (num === '3') return <>3<sup>rd</sup></>;
  return <>{num}<sup>th</sup></>;
}

function color(num) {
  if (num === '100') return "red";
  if (num === '1') return "goldenrod";
  if (num === '2') return "gray";
  if (num === '3') return "coral";
  if (num === '4') return "turquoise";
  if (num === '5') return "orchid";
}

const Match = ({match, players, placements, ...props}) => {
  let matchPlacements = placements.filter(placement => placement.Match === match.partitionKey + match.rowKey).sort(byPlayerNumber);
  let isFinished = matchPlacements.filter(placement => placement.Place).length == matchPlacements.length;
  let orderedPlacements = _.groupBy(matchPlacements, isFinished ? (placement => placement.Place) : null)

  return (
    <div>
      <div>
        <h5>{match.Name}</h5>
      </div>
      {isFinished ? <>
        {Object.keys(orderedPlacements).sort((a, b) => a - b).map(place => 
          <div key={place} className="row">
            {orderedPlacements[place].map(placement =>
              <div key={placement.Id} className="row">
                <div className="col-3">
                  <span style={{ fontFamily: "Knewave", color: color(place), textShadow: "-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black" }}>{ordianl(place)}</span>
                  {orderedPlacements[place].length > 1 && place < 100 ? "🔗" : ""}
                </div>
                <div className="col-6">{players.filter(player => placement.Player === player.partitionKey + player.rowKey)?.[0].Name}</div>
                <div className="col-3">{placement.Points !== undefined ? "💎" + placement.Points : ""}</div>
              </div>
            )}
          </div>
        )}
      </> : <>
        <div className="row">
          {matchPlacements.map(placement =>
            <div key={placement.Id} className="row">
              <div className="col-12">{players.filter(player => placement.Player === player.partitionKey + player.rowKey)?.[0].Name}</div>
            </div>
          )}
        </div>
      </>}
    </div>
  )
};

Match.propTypes = {};

Match.defaultProps = {};

export default Match;
